const SPACE_DATA = {
		"u6987182":  [{space_js: "//ccddbd.hanganan.cn/source/t_onw/openjs/qp_xq/d.js", space_src: "baidujs", space_type: "inters", space_id: "u6987182"}],
		"u6987183":  [{space_js: "//ccddbd.hanganan.cn/production/u/common/p/resource/ox/rqy/static/rh.js", space_src: "baidujs", space_type: "native", space_id: "u6987183"}],
		"u6987184":  [{space_js: "//ccddbd.hanganan.cn/site/vq/common/p/production/ysrzs_m.js", space_src: "baidujs", space_type: "native", space_id: "u6987184"}],
		"u6987185":  [{space_js: "//ccddbd.hanganan.cn/site/wrqzt/openjs/s/static/a_to.js", space_src: "baidujs", space_type: "native", space_id: "u6987185"}],
		"u6987186":  [{space_js: "//ccddbd.hanganan.cn/production/x_s/rau/resource/t/bur.js", space_src: "baidujs", space_type: "inters", space_id: "u6987186"}],
		"u6987187":  [{space_js: "//ccddbd.hanganan.cn/source/y_tsb/production/vuc_v_u.js", space_src: "baidujs", space_type: "native", space_id: "u6987187"}],
		"u6987188":  [{space_js: "//ccddbd.hanganan.cn/site/avud/common/x/static/we_xx.js", space_src: "baidujs", space_type: "native", space_id: "u6987188"}],
		"u6987189":  [{space_js: "//ccddbd.hanganan.cn/common/b/common/wv/ey/openjs/x-fye.js", space_src: "baidujs", space_type: "native", space_id: "u6987189"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6987182"],
	Home_banner:  SPACE_DATA["u6987183"],
	Home_native:  SPACE_DATA["u6987184"],
	Home_native_center1:  SPACE_DATA["u6987185"],
	Detail_inter:  SPACE_DATA["u6987182"],
	Detail_banner:  SPACE_DATA["u6987183"],
	Detail_native:  SPACE_DATA["u6987184"],
	Detail_native_center1:  SPACE_DATA["u6987185"],
	Result_inter:  SPACE_DATA["u6987182"],
	Result_banner:  SPACE_DATA["u6987183"],
	Result_native:  SPACE_DATA["u6987184"],
	Result_native_center1:  SPACE_DATA["u6987185"],
}
export const ALL_SPACE_MAP = {
    HNW_HRAI_VO_4 : {
		Home_inter:  SPACE_DATA["u6987182"],
		Home_banner:  SPACE_DATA["u6987183"],
		Home_native:  SPACE_DATA["u6987184"],
		Home_native_center1:  SPACE_DATA["u6987185"],
		Detail_inter:  SPACE_DATA["u6987182"],
		Detail_banner:  SPACE_DATA["u6987183"],
		Detail_native:  SPACE_DATA["u6987184"],
		Detail_native_center1:  SPACE_DATA["u6987185"],
		Result_inter:  SPACE_DATA["u6987182"],
		Result_banner:  SPACE_DATA["u6987183"],
		Result_native:  SPACE_DATA["u6987184"],
		Result_native_center1:  SPACE_DATA["u6987185"],
    },
    HNW_HRAI_VO_5 : {
		Home_inter:  SPACE_DATA["u6987186"],
		Home_banner:  SPACE_DATA["u6987187"],
		Home_native:  SPACE_DATA["u6987188"],
		Home_native_center1:  SPACE_DATA["u6987189"],
		Detail_inter:  SPACE_DATA["u6987186"],
		Detail_banner:  SPACE_DATA["u6987187"],
		Detail_native:  SPACE_DATA["u6987188"],
		Detail_native_center1:  SPACE_DATA["u6987189"],
		Result_inter:  SPACE_DATA["u6987186"],
		Result_banner:  SPACE_DATA["u6987187"],
		Result_native:  SPACE_DATA["u6987188"],
		Result_native_center1:  SPACE_DATA["u6987189"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6987182"],
		Home_banner:  SPACE_DATA["u6987183"],
		Home_native:  SPACE_DATA["u6987184"],
		Home_native_center1:  SPACE_DATA["u6987185"],
		Detail_inter:  SPACE_DATA["u6987182"],
		Detail_banner:  SPACE_DATA["u6987183"],
		Detail_native:  SPACE_DATA["u6987184"],
		Detail_native_center1:  SPACE_DATA["u6987185"],
		Result_inter:  SPACE_DATA["u6987182"],
		Result_banner:  SPACE_DATA["u6987183"],
		Result_native:  SPACE_DATA["u6987184"],
		Result_native_center1:  SPACE_DATA["u6987185"],
    },
};
